import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { SharedService } from 'app/shared/shared.service';

@Component({
  selector: 'ashcorp-field-text',
  templateUrl: './ashcorp-field-text.component.html',
  animations: fuseAnimations
})

export class Ashcorp_Field_Text_Component {
  @Input() form: FormGroup;
  @Input() label: string;
  @Input() name: string;
  @Input() show?: boolean = true;

  public helper: SharedService;

  constructor(private _shared_service: SharedService) {
    this.helper = this._shared_service;
  }
}
